<template>
  <v-card class="neu-glow">
    <help class="help--card" :helpId="52" />

    <v-card-title class="py-0">{{ name }} </v-card-title>
    <v-card-text>{{ toLocaleDateString(created_at) }}</v-card-text>
    <v-card-text class="pb-4 d-flex flex-column">
      <slot />

      <v-row class="mx-0">
        <v-icon left>comment</v-icon>
        {{
          comments_count === 0
            ? 'No hay comentarios aún'
            : comments_count + ' comentarios'
        }}
        <v-spacer />
        <vue-countdown-timer
          v-if="showCounter"
          @end_callback="$emit('countdownOver')"
          :start-time="startAt"
          :end-time="endAt"
          :interval="1000"
          :start-label="''"
          :end-label="''"
          label-position="begin"
          :end-text="'¡Lección completada!'"
          :day-txt="'días'"
          :hour-txt="'horas'"
          :minutes-txt="'minutos'"
          :seconds-txt="'segundos'"
          :show-zero="false"
        />
      </v-row>
    </v-card-text>
    <!-- <v-card-text class="pt-0">
      <v-list-item class="px-0">
        <v-list-item-avatar>
          <v-img :src="avatar" />
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title>{{ name }}</v-list-item-title>
        </v-list-item-content>
        <v-row align="center" justify="end">
          <v-btn icon>
            <v-icon>more_vert</v-icon>
          </v-btn>
        </v-row>
      </v-list-item>
    </v-card-text> -->
  </v-card>
</template>

<script>
  import { toLocaleDateString } from '@/utils/helpers'
  export default {
    name: 'LessonContent',
    props: {
      id: {
        type: Number,
        default: 0,
      },
      duration: {
        type: Number,
        default: 0,
      },
      name: {
        type: String,
        default: '',
      },
      course: {
        type: Object,
        default: function () {
          return {}
        },
      },
      cover: {
        type: Object,
        default: null,
      },
      comments_count: {
        type: [String, Number],
        default: 0,
      },
      created_at: {
        type: [String, Date],
        default: String(new Date()),
      },
    },
    data() {
      return {
        showCounter: false,
        startAt: new Date(),
        endAt: new Date(new Date().getTime() + 5 * 60000),
      }
    },
    created() {},
    watch: {
      duration(newValue) {
        if (newValue !== 0) {
          this.verifyStart()
        }
      },
    },

    methods: {
      toLocaleDateString: toLocaleDateString,
      verifyStart() {
        this.startAt = new Date()
        this.endAt = new Date(new Date().getTime() + this.duration * 60000)
        if (
          !this.$session.get('lessonStarted') ||
          parseInt(this.$session.get('lessonStarted').id) !==
            parseInt(this.$route.params.lessonId)
        ) {
          this.$session.set('lessonStarted', {
            id: this.id,
            startAt: this.startAt,
            endAt: this.endAt,
          })
        } else {
          this.startAt = new Date(this.$session.get('lessonStarted').startAt)
          this.endAt = new Date(this.$session.get('lessonStarted').endAt)
        }
        this.showCounter = true
      },
    },
    beforeDestroy() {
      this.$session.remove('lessonStarted')
    },
  }
</script>
