<template>
  <div class="vuse-content-wrapper lesson-detail">
    <v-row no-gutters align="center" justify="space-between">
      <smart-breadcrumbs :items="navigationElements" />
      <v-btn
        text
        x-small
        v-if="lesson.course_id"
        color="primary"
        dark
        @click.prevent="
          $router.push({
            name: 'business/elearning/UserCourseDetail',
            params: { courseId: lesson.course_id },
            force: true,
          })
        "
        >Volver al curso
      </v-btn>
    </v-row>
    <v-card color="transparent" flat>
      <v-toolbar dense class="py-1" color="primary">
        <v-toolbar-title class="text-h6" style="width: 100%">
          {{ lessonCourse ? ' ' + lessonCourse.name : '' }}
        </v-toolbar-title>
        <v-col cols="12" class="py-1 px-0">
          <v-row no-gutters align="center">
            <span class="caption mr-2"> Progreso general: </span>
            <v-col>
              <v-tooltip right>
                <template v-slot:activator="{ on, attrs }">
                  <v-progress-linear
                    v-bind="attrs"
                    v-on="on"
                    class=""
                    rounded
                    :color="progressColor"
                    height="15"
                    style="max-width: 200px"
                    :value="progressValue"
                    ><template v-slot:default="{ value }">
                      <span class="caption" style="color: white">{{ value }}%</span>
                    </template></v-progress-linear
                  >
                </template>
                <span>{{ courseStatus }}</span></v-tooltip
              >
            </v-col>
          </v-row>
        </v-col>
        <v-row no-gutters align="center">
          <!-- <v-col cols="12">
          </v-col> -->
          <!-- <span class="caption mr-2"> Progreso general: </span>
          <v-col>
            <v-tooltip right>
              <template v-slot:activator="{ on, attrs }">
                <v-progress-linear
                  v-bind="attrs"
                  v-on="on"
                  class=""
                  rounded
                  :color="progressColor"
                  height="15"
                  style="max-width: 200px"
                  :value="progressValue"
                  ><template v-slot:default="{ value }">
                    <span class="caption" style="color: white">{{ value }}%</span>
                  </template></v-progress-linear
                >
              </template>
              <span>{{ courseStatus }}</span></v-tooltip
            >
          </v-col> -->
          <!-- <v-col cols="12" sm="6"
            ><span style="float: right" v-if="assignedEvaluation.user"
              ><small
                >Evaluador:
                {{
                  assignedEvaluation.user.name + ' ' + assignedEvaluation.user.lastname
                }}</small
              ></span
            ><br /><span style="float: right" v-if="assignedEvaluation.company"
              ><small>Empresa: {{ assignedEvaluation.company.name }}</small></span
            ><br /><span style="float: right" v-if="assignedEvaluation.date">
              <small>{{ toLocaleDateString(assignedEvaluation.date) }}</small></span
            ></v-col
          > -->
        </v-row>
      </v-toolbar>
      <!-- <v-row align="center" class="mx-0">
        <v-col cols="12">
          <v-card-title class="pa-0">
            <strong>{{ lessonCourse ? " " + lessonCourse.name : "" }} </strong>
            <v-tooltip right>
              <template v-slot:activator="{ on, attrs }">
                <v-progress-linear
                  v-bind="attrs"
                  v-on="on"
                  class="ml-sm-2"
                  height="20"
                  style="width: 200px"
                  :color="progressColor"
                  :value="progressValue"
                  ><template v-slot:default="{ value }">
                    <span style="color: white">{{ Math.ceil(value) }}%</span>
                  </template></v-progress-linear
                >
              </template>
              <span>{{ courseStatus }}</span></v-tooltip
            >
          </v-card-title>
        </v-col>
      </v-row> -->
      <v-row class="mx-0">
        <v-col
          cols="12"
          :md="!lesson.supportMaterials || lesson.supportMaterials.length === 0 ? 12 : 9"
        >
          <lesson-content
            class="lesson-content"
            v-bind="lesson"
            v-if="lesson"
            v-on:countdownOver="saveWatchedLesson()"
          >
            <iframe
              v-if="lesson.type === 0"
              width="100%"
              class="video"
              :src="lesson.url"
              title="Cloud Embedded Content"
              frameborder="0"
              allow="autoplay"
              allowfullscreen
            />

            <iframe
              v-if="lesson.type === 2 && lesson.mainFile && lesson.mainFile.route"
              :src="`https://docs.google.com/gview?url=${lesson.mainFile.route}&embedded=true`"
              style="width: 100%; height: 600px"
              frameborder="0"
            />
            <vue-editor
              v-model="lesson.description"
              disabled
              :editor-toolbar="customLessonToolbar"
              v-if="lesson.description"
              class="my-4"
            />
          </lesson-content>
          <v-row class="mx-0 my-4">
            <v-tooltip top v-if="lesson.previous_lesson">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-if="lesson.previous_lesson"
                  text
                  v-bind="attrs"
                  v-on="on"
                  color="primary"
                  dark
                  class="mb-2"
                  style="float: right"
                  @click.prevent="previousLesson()"
                  >Anterior
                </v-btn>
              </template>
              <span v-if="lesson.previous_lesson">{{ lesson.previous_lesson.name }}</span>
            </v-tooltip>
            <v-spacer />
            <v-tooltip top v-if="lesson.next_lesson">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-if="lesson.next_lesson"
                  :disabled="!watchedLesson"
                  text
                  v-bind="attrs"
                  v-on="on"
                  color="primary"
                  dark
                  class="mb-2"
                  style="float: right"
                  @click.prevent="nextLesson()"
                  >Siguiente
                </v-btn>
              </template>
              <span v-if="lesson.next_lesson">{{ lesson.next_lesson.name }}</span>
            </v-tooltip>
          </v-row>
        </v-col>
        <v-col
          cols="12"
          md="3"
          v-if="lesson.supportMaterials && lesson.supportMaterials.length > 0"
        >
          <v-sheet class="transparent rounded">
            <v-card class="neu-glow">
              <v-toolbar color="primary" light>
                <v-toolbar-title
                  title="Material de apoyo"
                  class="white--text text-body-2"
                >
                  Material de apoyo
                </v-toolbar-title>
              </v-toolbar>
              <v-list two-line subheader>
                <v-list-item
                  v-for="file in lesson.supportMaterials"
                  :key="file.id"
                  link
                  dense
                  class="px-0"
                >
                  <a
                    :href="file.route"
                    rel="noopener"
                    target="_blank"
                    style="text-decoration: none"
                  >
                    <v-btn icon>
                      <v-icon> mdi-folder </v-icon>
                    </v-btn>
                  </a>

                  <v-list-item-content>
                    <a
                      :href="file.route"
                      rel="noopener"
                      target="_blank"
                      style="text-decoration: none"
                    >
                      <v-list-item-title>{{
                        file.name ? file.name : 'Archivo ' + (file.id + 1)
                      }}</v-list-item-title>

                      <v-list-item-subtitle
                        >{{ file.format }}
                        {{
                          file.size ? `| ${fileSize(file.size)}kB` : ''
                        }}</v-list-item-subtitle
                      >
                    </a>
                  </v-list-item-content>

                  <v-list-item-action>
                    <a :href="file.route" download style="text-decoration: none">
                      <v-btn icon small>
                        <v-icon color="grey lighten-1"> mdi-download </v-icon>
                      </v-btn>
                    </a>
                  </v-list-item-action>
                </v-list-item>
              </v-list>
            </v-card>
          </v-sheet>

          <br />
          <v-card
            class="mx-auto neu-glow"
            max-width="250"
            v-if="lessonCourse.exam && courseCompleted"
          >
            <v-card-actions>
              <v-btn
                block
                x-small
                color="primary"
                height="30px"
                @click="reveal = !reveal"
              >
                Realizar examen
              </v-btn>
            </v-card-actions>

            <v-expand-transition>
              <v-card
                v-if="reveal"
                class="transition-fast-in-fast-out v-card--reveal neu-glow"
                style="height: 100%"
              >
                <v-card-text class="pb-0">
                  <span class="text--primary" v-if="lessonCourse && lessonCourse.exam">
                    Duración: {{ lessonCourse.exam.duration }} min </span
                  ><br />
                  <span
                    class="text--primary"
                    v-if="
                      lessonCourse && lessonCourse.exam && lessonCourse.exam.questions
                    "
                  >
                    Preguntas: {{ lessonCourse.exam.questions.length }} </span
                  ><br />
                  <span class="text--primary" v-if="lessonCourse && lessonCourse.exam">
                    Intentos: {{ lessonCourse.exam.attempts }} </span
                  ><br />
                </v-card-text>
                <v-card-actions class="pt-0">
                  <v-btn
                    class="mx-auto"
                    text
                    color="teal accent-4"
                    @click="
                      $router.push({
                        name: 'business/elearning/UserExam',
                        params: { examId: lessonCourse.exam.id },
                      })
                    "
                  >
                    Iniciar
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-expand-transition>
          </v-card>
        </v-col>
      </v-row>
      <v-snackbar v-model="snackbar" absolute top right color="success">
        <v-icon dark>check_circle</v-icon>
        Haz completado el curso
        <strong>{{ lessonCourse ? lessonCourse.name : '' }}</strong>
      </v-snackbar>
    </v-card>
  </div>
</template>
<script>
  import LessonContent from '@/components/Elearning/LessonContent'
  import { decryptData } from '@/utils/encryption'

  export default {
    name: 'UserLessonDetail',
    components: {
      LessonContent,
    },
    data: () => ({
      reveal: false,
      snackbar: false,
      courseCompleted: false,
      watchedLesson: false,
      lesson: {},
      lessonCourse: [],
      customLessonToolbar: [[]],
      navigationElements: [
        {
          text: 'Learning',
          disabled: false,
          href: '/business/elearning/user-panel',
        },
        {
          text: 'Mis Cursos',
          disabled: false,
          href: '/business/elearning/user-panel/courses',
        },
        {
          text: 'Detalles del curso',
          disabled: false,
          href: '/business/elearning/user-panel/courses',
        },
        {
          text: 'Lección',
          disabled: true,
          href: '/business/elearning/user-panel/lessons',
        },
      ],
    }),
    created() {
      this.getLessonCourse()
      this.getLesson()
    },
    computed: {
      progressValue() {
        if (
          this.lessonCourse.lessons_completed_count &&
          this.lessonCourse.lessons_count
        ) {
          return Math.ceil(
            (this.lessonCourse.lessons_completed_count /
              this.lessonCourse.lessons_count) *
              100
          )
        } else {
          return 0
        }
      },
      progressColor() {
        if (this.progressValue === 100 || this.lessonCourse.completed === true) {
          return 'success darken-2'
        } else {
          return 'teal'
        }
      },
      courseStatus() {
        if (this.lessonCourse.completed === true) {
          return '¡Curso completado!'
        } else {
          return 'Progreso del curso'
        }
      },
    },
    methods: {
      fileSize(size) {
        return parseFloat(size).toFixed(2)
      },
      async getLessonCourse() {
        this.$axios
          .get(
            'courses?lesson_id=' +
              this.$route.params.lessonId +
              '&with[]=exam&limit=1&appends[]=lessons_count&appends[]=lessons_completed_count&appends[]=completed'
          )
          .then((response) => {
            this.lessonCourse = response.data[0]
          })
      },
      async getLesson() {
        await this.$axios
          .get(
            'lessons/' +
              this.$route.params.lessonId +
              '?with[]=cover&appends[]=previous_lesson&appends[]=next_lesson&with[]=uploads'
          )
          .then((response) => {
            this.lesson = response.data
            this.lesson.mainFile = this.lesson.uploads.find(
              (upload) => upload.type === 'main'
            )
            this.lesson.supportMaterials = this.lesson.uploads.filter(
              (upload) => upload.type === 'document'
            )
          })
      },
      saveWatchedLesson() {
        this.$axios
          .patch('lessons/' + this.$route.params.lessonId, {
            users: [decryptData(this.$session.get('userId'))],
          })
          .then(() => {
            this.watchedLesson = true
            this.getLessonCourse()
            if (!this.lesson.next_lesson) {
              this.saveWatchedCourse()
            }
          })
      },
      saveWatchedCourse() {
        const time = new Date()
        const timeFormat = new Date(
          time.getTime() - time.getTimezoneOffset() * 60000
        ).toISOString()
        if (this.lesson.course_id !== undefined) {
          this.$axios
            .patch('courses/' + this.lesson.course_id, {
              courseUser: [
                {
                  user_id: decryptData(this.$session.get('userId')),
                  points: this.lessonCourse ? this.lessonCourse.points : 100,
                  exam_approved: 0,
                  date:
                    timeFormat.split('T')[0] +
                    ' ' +
                    timeFormat.split('T')[1].split('.')[0],
                },
              ],
            })
            .then(() => {
              this.courseCompleted = true
              this.snackbar = true
            })
        }
      },
      nextLesson() {
        this.$router.push({
          name: 'business/elearning/UserLessonDetail',
          params: { lessonId: this.lesson.next_lesson.id },
          force: true,
        })
        location.reload()
      },
      previousLesson() {
        this.$router.push({
          name: 'business/elearning/UserLessonDetail',
          params: { lessonId: this.lesson.previous_lesson.id },
          force: true,
        })
        location.reload()
      },
    },
    beforeDestroy() {
      this.$session.remove('lessonStarted')
    },
  }
</script>
<style lang="scss">
  // @import '~vue2-editor/dist/vue2-editor.css';
  .lesson-detail {
    .v-breadcrumbs {
      padding: 5px 10px !important;
    }
    .v-breadcrumbs__item {
      font-size: 12px;
    }
    .v-toolbar,
    .v-toolbar__content {
      height: auto !important;
      flex-direction: column;
      align-items: start;
    }
    .v-list--two-line .v-list-item,
    .v-list-item--two-line {
      min-height: 40px;
    }
    .lesson-content {
      img,
      iframe {
        max-width: 100%;
        height: auto;
        margin: 0 auto;

        object-fit: contain;

        &.video {
          aspect-ratio: 16/9;
        }
      }
      iframe {
        min-height: 250px;
      }
      .ql-toolbar {
        display: none;
      }
      .ql-editor {
        padding: 0;
      }
      .ql-container {
        border: none;
      }
    }
  }
</style>
